import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeRightText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeMiddleText,
    placeSliderSwitch,
    placeShuffle,
    placeLogo,
    placeRedo,
    placeUndo,
    drawPoint,
    drawSegment,
    placeStartOver,
    createSpace,
    placeErase
} from '../Utils';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  ////////////////////////////////////////////////////////////////////
  //var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 8, 8, -2],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  var brd1 = createSpace(-2,8.,-2.,8);
  makeResponsive(brd1);
  brd1.options.layer['image'] =14;
  placeTitle(brd1, 'Median of Numbers', '(Below are Mary\'s soccer scores from 5 games)');
  placeLogo(brd1);
  brd1.create('image', ['/assets/finger.svg', [-1+0.2, 6.2],[0.6, 0.6]], {opacity:1, fixed:true});
  brd1.create('image', ['/assets/finger.svg', [-2+0.2, 6-0.2],[0.6, 0.6]], {opacity:1, fixed:true, rotate:-90});
/////////////////////////////////////////////////
  var type1 = 2;
  var type2 = 7;
  var type3 = 5
  var type4 = 1
  var type5 = 3;
  var dataArr=[type1, type2, type3, type4, type5];
  var IndArr=[0, 1, 2, 3, 4];
  var sortInd=IndArr.sort(function (a, b){return dataArr[a] > dataArr[b] ? 1 : dataArr[a] < dataArr[b] ? -1 : 0 });
  var sortedArr=dataArr.sort();
  console.log(sortInd);
  var bulldog=[];
  var doberman=[];
  var greyhound=[];
  var dalmatian=[];
  var sharpei =[];
  var P1 = drawPoint(brd1, -1, 6);
  P1.setAttribute({visible:false});
  var P2 = drawPoint(brd1, 0, 5);
  P2.setAttribute({visible:false});
  var sep =drawSegment(brd1, P1, P2);
  sep.setAttribute({strokeColor:'red'});
  var jm=0; var mm=0; var dim=0; var sm=0; var km=0; var a=1;
  var bd = function(){
  for(let i=0;i<=10;i++)
   {
   bulldog[i] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(i), 0.25],[0.5, 0.5]], {opacity:1, fixed:true, visible:()=>i<(1-mm)*type1+mm*sortedArr[0]});
   }}
  var dm = function(){
  for(let j=0;j<=10;j++)
   {
   doberman[j] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(j), 1.25],[0.5, 0.5]], {opacity:1, fixed:true, visible:()=>j<(1-mm)*type2+mm*sortedArr[1]});
   }}
  var gh = function(){
  for(let k=0;k<=10;k++)
   {
   greyhound[k] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(k), 2.25],[0.5, 0.5]], {opacity:1, fixed:true, visible:()=>k<(1-mm)*type3+mm*sortedArr[2]});
   }}
  var dmt = function(){
  for(let l=0;l<=10;l++)
   {
   dalmatian[l] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(l), 3.25],[0.5, 0.5]], {opacity:1, fixed:true, visible:()=>l<(1-mm)*type4+mm*sortedArr[3]});
   }}
  var sp = function(){
  for(let m=0;m<=10;m++)
   {
   sharpei[m] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(m), 4.25],[0.5, 0.5]], {opacity:1, fixed:true,visible:()=>m<(1-mm)*type5+mm*sortedArr[4]});
   }}
  bd(); dm();gh();dmt();sp();
  /////////////////////////////////////////////////////////////////////////////////////
  var next = placeRedo(brd1);
  next.setAttribute({visible:()=>jm<2});
  next.setLabel('Tap to Go to Next Step');
  next.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  next.on('over', function (){this.label.setAttribute({visible:true});});
  next.on('out', function (){this.label.setAttribute({visible:false});});
  //////////////////////////////////////////////////////////////////////////////////
  var prev = placeUndo(brd1,'left');
  prev.setAttribute({visible:()=>jm>0});
  prev.setLabel('Tap to Go to Previous Step');
  prev.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  prev.on('over', function(){this.label.setAttribute({visible:true});});
  prev.on('out', function(){this.label.setAttribute({visible:false});});
  ////////////////////////////////////////////////////////////////////////////////
  var stover = placeErase(brd1);
  stover.setAttribute({visible:()=>jm==2});
  stover.setLabel('Tap to Start Over');
  stover.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  stover.on('over', function(){this.label.setAttribute({visible:true});});
  stover.on('out', function(){this.label.setAttribute({visible:false});});
  stover.on('down', function(){jm=0;mm=0});
///////////////////////////////////////////////////////////////////////////////
  var reload = placeShuffle(brd1, 'left');
  reload.setLabel('Tap to Shuffle', 'left');
  reload.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.setAttribute({visible:()=>jm==0});
  next.on('down', function(){
    if(jm<2)
    {
    jm+=1;
    }
    else
    {
    jm=2;
    }
    if(jm<1)
    {
    mm+=1;
    }
    else
    {
    mm=1;
    }
  });
  prev.on('down', function(){
    if(jm==1)
    {
    mm-=1;
    }
    else if(jm==0)
    {
    mm=0;
    }
    else
    {
    mm=1;
    }
    if(jm>0)
    {
    jm-=1;
    }
    else
    {
    jm=0;
    }
  });
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){
  type1=Math.round(Math.random()*3)+3;
  type2=Math.round(Math.random()*6)+1;
  type3=Math.round(Math.random()*5)+1;
  type4=Math.round(Math.random()*7)+1;
  type5=Math.round(Math.random()*2)+1;
  dataArr=[type1, type2, type3, type4, type5];
  IndArr=[0,1,2,3,4];
  sortInd=IndArr.sort(function (a, b){return dataArr[a] > dataArr[b] ? 1 : dataArr[a] < dataArr[b] ? -1 : 0 });
  sortedArr=dataArr.sort();
  jm=0;mm=0;});
  brd1.create('text', [-1+0.05, 5., 'Game'],{display:'internal',anchorX:'left', anchorY:'bottom', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //
  brd1.create('text', [-0.05, 6, 'Score'],{display:'internal',anchorX:'right', anchorY:'top', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //
  var john =brd1.create('text', [-1+0.5, 0.5, ()=>'Game '+1*((1-mm)*0+mm*IndArr[0]+1)],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var mary =brd1.create('text', [-1+0.5, 1.5, ()=>'Game '+1*((1-mm)*1+mm*IndArr[1]+1)],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var david =brd1.create('text', [-1+0.5, 2.5, ()=>'Game '+1*((1-mm)*2+mm*IndArr[2]+1)],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var sarah = brd1.create('text', [-1+0.5, 3.5, ()=>'Game '+1*((1-mm)*3+mm*IndArr[3]+1)],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var katie = brd1.create('text', [-1+0.5, 4.5, ()=>'Game '+1*((1-mm)*4+mm*IndArr[4]+1)],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //////////////////////////////////////////////////
//  brd1.create('text', [6, 0.5, function(){return '' + type1.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 1., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 1.5, function(){return '' + type2.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 2., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 2.5, function(){return '' + type3.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 3., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 3.5, function(){return '' + type4.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 4., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, 4.5, function(){return '' + type5.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [3, 5.5, 'Step 1: Arrange the scores in ascending order.'],{visible:()=>jm==1, color:'blue',  display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [3, 5.5, 'Step 2: Find the number located midway.'],{visible:()=>jm==2, color:'blue', display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//   brd1.create('text', [4.8, -0.5, 'This is Mary\'s weekly total'],{visible:()=>jm==1, color:'red', display:'internal',anchorX:'right', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [3, -0.3, ()=>'Median is '+ sortedArr[2]+ ' (Scored during game '+ (sortInd[2]+1)*1+' )'],{visible:()=>jm==2, display:'internal',color:'red', anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('image', ['/assets/finger.svg', [-1.7, 2.3],[0.4, 0.4]], {visible:()=>jm>1, opacity:1, fixed:true});
//
//  brd1.create('text', [()=>6+(jm-1)*0.1, -0.5, function(){return ''+ 1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf()) + '/5 = '+1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf())/5 + ' goals' ;}],{visible:()=>jm==2, display:'internal',anchorX:'middle', color:'red', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
//  brd1.create('text', [6, -0.5, function(){return ''+ 1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf()) + ' goals';}],{color:'red', visible:()=>jm==1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
// var P3 = drawPoint(brd1, 5, 0);
//  P3.setAttribute({visible:false});
//  var P4 = drawPoint(brd1, 7, 0);
//  P4.setAttribute({visible:false});
//  var net =drawSegment(brd1, P3, P4);
//  net.setAttribute({visible:()=>jm>=1, strokeColor:'red'});
//////////////////////////////////////////////////////////////////
}
}
export default Boxes;
