<template>
  <div>
    <h3 ref="intro">
      Definition of Median
    </h3>
    <p class="mt-2">
      The median of a set of numbers is the "middle" value in the sorted set. To find the median of a set of numbers &mdash; <br>
      <ul class="a mt-2">
        <li> You must first arrange the data in ascending order &mdash; meaning from smallest to greatest. </li>
        <li> Next, select the number that is located halfway in this sorted set. This number would be the median of the data.</li>
      </ul>
      <br>
      Let's take an example. Suppose you were given the following set of numbers &mdash;
      $$\{6, 9, 2, 8, 10\}$$
      To find the median of this set, we first arrange this set in ascending order &mdash;
      $$\{2, 6, 8, 9, 10\}$$
      Next, we select the number that is located in the "middle" of the list &mdash; which is 8 in this case.
      Thus, the median of the above set of numbers would be 8.
    </p>
    <h3 ref="pg">
      MagicGraph | Finding Median of a Set of Numbers
    </h3>
    <p class="mt-2">
      Through this MagicGraph, you will learn, in a step by step manner, how to calculate the median of a set of numbers.
      <br>
      Mary is a soccer player. She plays soccer 5 days a week (from Monday till Friday). Shown in the MagicGrap are Mary's daily soccer scores for a week. Your goal is to find Mary's daily average soccer score based on this information. Use the icons on the MagicGraph to walk through the exercise.
      <br>
      Tap on <i class="shuffler ma-1" /> icon to shuffle and generate a different set of daily scores.
      Tap on the <i class="next ma-1" /> icon to go to the next step.
      To go to the previous step, tap on the <i class="previous ma-1" /> icon. You can tap on the <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Average',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Finding Median';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Median of Numbers',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about finding the median of a set of numbers.'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
